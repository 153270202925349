export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const COMPANY_REGISTER_SUCCESS = 'COMPANY_REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const COMPANY_LOGIN_SUCCESS = 'COMPANY_LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const GET_PROFILE = 'GET_PROFILE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const CLEAR_PROFILE = 'CLEAR_PROFILE'
export const DELETE_PROFILE = 'DELETE_PROFILE'
export const PROFILE_ERROR = 'PROFILE_ERROR'
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED'
export const GET_PROFILES = 'GET_PROFILES'
export const GET_POSTS = 'GET_POSTS'
export const GET_POST = 'GET_POST'
export const POST_ERROR = 'POST_ERROR'
export const UPDATE_LIKES = 'UPDATE_LIKES'
export const DELETE_POST = 'DELETE_POST'
export const ADD_POST = 'ADD_POST'
export const ADD_COMMENT = 'ADD_COMMENT'
export const REMOVE_COMMENT = 'REMOVE_COMMENT'
export const COMPANY_LOADED = 'COMPANY_LOADED'
export const JOB_ERROR = 'JOB_ERROR'
export const GET_JOBS = 'GET_JOBS'
export const SEARCH_JOBS = 'SEARCH_JOBS'
export const GET_JOB = 'GET_JOB'
export const ADD_JOB = 'ADD_JOB'
export const UPDATE_JOBS = 'UPDATE_JOBS'
export const DELETE_JOB = 'DELETE_JOB'
export const ADMIN_LOADED = 'ADMIN_LOADED'
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS'
export const GET_APPLIED_JOBS = 'GET_APPLIED_JOBS'
export const GET_FAVORITE_JOBS = 'GET_FAVORITE_JOBS'
export const DELETE_APPLIED_JOB = 'DELETE_APPLIED_JOB'
export const REMOVE_FAVORITE_JOB = 'REMOVE_FAVORITE_JOB'
export const ADD_DATA = 'ADD_DATA'
export const ADD_SCHEDULED_INTERVIEW = 'ADD_SCHEDULED_INTERVIEW'
export const GET_INTERVIEWS = 'GET_INTERVIEWS'
export const SET_MEETING_NULL = 'SET_MEETING_NULL'
export const SET_APPLICANT_SCORE= 'SET_APPLICANT_SCORE'


// actions/types.js

// export const SET_SCORE = 'SET_SCORE';
